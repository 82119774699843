<template>
  <section
    :class="[
      'ui-py-2xl ui-flex ui-items-center ui-justify-center ui-gap-lg md:ui-gap-2xl ui-p-sm',
      themeConfig[content.backgroundColor].bg,
      content.imagePosition == 'left'
        ? 'ui-flex-col sm:ui-flex-row'
        : 'ui-flex-col sm:ui-flex-row-reverse',
    ]"
  >
    <NuxtPicture
      loading="lazy"
      :src="content.imageUrl"
      quality="85"
      :alt="content.alt"
      :img-attrs="{
        class:
          'ui-w-[328px] ui-h-[232px] sm:ui-w-[259px] sm:ui-h-[184px] md:ui-w-[447px] md:ui-h-[315px] lg:ui-w-[35.5rem] lg:ui-h-[25rem]',
      }"
    />
    <section class="ui-w-[328px] md:ui-w-[447px]">
      <h2
        class="ui-mb-xs ui-text-xl ui-leading-xl sm:ui-text-2xl sm:ui-leading-2xl md:ui-text-4xl md:ui-leading-4xl lg:ui-text-5xl lg:ui-leading-5xl ui-font-semibold"
      >
        {{ content.title }}
      </h2>

      <div
        v-html="content.description"
        :class="[
          'ui-mb-sm rich-text',
          themeConfig[content.backgroundColor].text,
        ]"
      />

      <Link
        v-if="content.link.href"
        :href="content.link.href"
        :class="themeConfig[content.backgroundColor].linkText"
      >
        {{ content.link.text }}
      </Link>
    </section>
  </section>
</template>

<script setup lang="ts">
import { ImageWithTextProps } from "./types";
import Link from "../Link/Link.vue";

const themeConfig = {
  white: {
    bg: "ui-bg-transparent ui-text-charcoal-default",
    text: "ui-text-charcoal-default",
    linkText: "ui-text-mkm-blue-light",
  },
  "light grey": {
    bg: "ui-bg-grey-light ui-text-charcoal-default",
    text: "ui-text-charcoal-default",
    linkText: "ui-text-mkm-blue-light",
  },
  "dark mode": {
    bg: "ui-bg-charcoal-default ui-text-white",
    text: "ui-text-white",
    linkText: "ui-text-mkm-blue-lighter",
  },
};

const { content } = defineProps<ImageWithTextProps>();
</script>
